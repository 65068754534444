import React from "react";

import "./styles.scss";

const PagePreloader = () => {
  return (
    <div id="p_prldr">
      <div className="contpre">
        <span className="svg_anm" id="svg_anm"></span>
      </div>
    </div>
  );
};

export default PagePreloader;
