import params from "../../params.json";
import { socialMediaLinks } from '@consts/socialMediaLinks.js';

const projectCode = process.env.REACT_APP_PROJECT_CODE || "test";

const {
  SERVICE_URL: serviceUrl,
  SOURCE_URL: sourceUrl,
  AUTH_URL: authUrl,
  EMAIL_URL: emailUrl,
  REQUEST_PARAMS: requestParams,
} = params[projectCode];

if (!serviceUrl || !sourceUrl || !requestParams) {
  throw new Error("Invalid env params");
}

const headers = {
  'Content-type': 'application/json; charset=utf-8',
};

const requestBody = {
  ...requestParams,
};

const createSession = () => {
	return new Promise( async (resolve, reject) => {
    try {
      const response = await fetch( `${serviceUrl}/commonApi/Session`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          "SourceUrl": document.location.href,
        })})

      const data = await response.json()

      if ( response.ok ) {
        localStorage.setItem( 'guid', data )
        resolve( `${authUrl}/?CommonSessionId=${data}` )
      }
      else reject()  
    } catch ( e ) {
      reject( e )
    }
	})
}

const getInitialCalcData = () => {
  return new Promise(async (resolve, reject) => {
    const sessionID = localStorage.getItem('guid') 
    try {
      const response = await fetch(`${serviceUrl}/commonApi/pdlParams/${sessionID}`);
      const data = await response.json();
      if (response.ok) resolve(data);
      else reject();
    } catch (e) {
      reject(e);
    }
  });
};

const getCalculatedDataByParams = ({ Amount, Term }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = {
        Amount,
        Term,
        ...requestBody,
      };
      const response = await fetch(`${serviceUrl}/commonApi/pdlParams`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          ...headers,
          SessionId: localStorage.getItem('guid'),
        },
      });
      const data = await response.json();
      if (response.ok) resolve(data);
      else reject();
    } catch (e) {
      reject(e);
    }
  });
};

const getRedirectToServiceLinkByParams = ({ Amount, Term  }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = {
        Amount,
        Term,
        ...requestBody,
      };
      const response = await fetch(`${serviceUrl}/commonApi/preOrder`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          ...headers,
          SessionId: localStorage.getItem('guid'),
        },
      });
      const data = await response.json();
      if (response.ok) resolve(data);
      else reject();
    } catch (e) {
      reject(e);
    }
  });
};

const sendMail = ({ subject, text, emailTo, pinnedFiles }) => {
  return new Promise(async (resolve, reject) => {
    const payload = {
      subject,
      text,
      emailTo: emailTo ?? socialMediaLinks.email,
      emailFrom: socialMediaLinks.complaintEmail,
      pinnedFiles,
    };
    try {
      const response = await fetch(`${emailUrl}/feedback/email`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          ...headers,
        }
      });
      const data = await response.text();
      if (response.ok) resolve(data);
      else reject();
    } catch (e) {
      reject(e);
    }
  });
}

const sendContacts = ({ name, phoneNumber }) => {
  return new Promise(async (resolve, reject) => {
    const text = ` Nombre: ${name};\n Teléfono de contacto: ${phoneNumber};\n Este cliente le pide que se comunique con él.`;
    const subject = "Contáctenos";
    try {
      const data = await sendMail({ text, subject });
      resolve(data);
    } catch (e) {
      reject(e);
    }
  });
};

const sendFeedback = ({ name, place, feedback, phoneNumber, email  }) => {
  const text = ` Nombre: ${name};\n Domicilio: ${place};\n Opinión: ${feedback};\n ${phoneNumber ? `Teléfono: ${phoneNumber};\n` : ''}${email ? ` Email: ${email};\n` : ''}`;
  const subject = "Testimonios de nuestros clientes";
  return new Promise(async (resolve, reject) => {
    try {
      const data = await sendMail({ text, subject });
      resolve(data);
    } catch (e) {
      reject(e);
    }
  });
};

const sendReport = ({ name, phoneNumber, email, dni, description }, file) => {
  const subject = "Informar un problema";
  const text = ` Nombre y Apellido: ${name};\n Teléfono: ${phoneNumber};\n Email: ${email};\n DNI: ${dni};\n Descripción: ${description};\n`;

  const sendMailPromise = async (pinnedFile) => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = sendMail({
          subject,
          text,
          pinnedFiles: pinnedFile ? [pinnedFile] : [],
        });
        resolve(data);
      } catch (e) {
        reject(e);
      }
    });
  };

  if (file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = async function () {
      const base64String = reader.result.split(",")[1];

      const pinnedFile = {
        fileName: file.name,
        base64String,
        mimeType: file.type,
      };

      return sendMailPromise(pinnedFile);
    };
  } else {
    return sendMailPromise();
  }
};

const sendComplaint = ({ name, surname }, pinnedFiles, email) => {
  const text = `Estimado ${name} ${surname} su recurso se estudiará en un plazo de treinta días. Espere una respuesta a su correo electrónico.`;
  const subject = "Reclamación sobre el producto servicio (DoctorSol)";
  const getParams = () => ({
    subject,
    text,
    emailTo: email,
    pinnedFiles
  })

  return new Promise(async (resolve, reject) => {
    try {
      const data = sendMail( getParams() );
      resolve(data);
    } catch (e) {
      reject(e);
    }
  });
};


export {
  serviceUrl,
  sourceUrl,
  getInitialCalcData,
  getCalculatedDataByParams,
  getRedirectToServiceLinkByParams,
  sendFeedback,
  sendReport,
  sendContacts,
  sendComplaint,
  createSession,
};
