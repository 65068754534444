import React, { createContext, useState, useContext, useEffect } from 'react'
import { createSession } from "@services/api"

const AuthContext = createContext( null )

export const AuthProvider = ({ children }) => {
  const [authZoneLink, setAuthZoneLink] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  useEffect( () => {
    const initSession = async () => {
      try {
        const href = await createSession()
        setAuthZoneLink( href )
      } catch ( error ) {
        console.warn( 'Не удалось получить id сессии: ', error )
      } finally {
        setIsLoading( false )
      }
    }
    initSession()
  }, [] )
  

  return (
    <AuthContext.Provider value={{ authZoneLink, setAuthZoneLink, isLoading }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthState = () => {
  return useContext(AuthContext);
}
