import React from "react";

const LeftBlock = ({ isActive }) => {
  return (
    <div
      className={`point-menu-text ${isActive ? "active" : ""}`}
      id="left"
    >
      <p>Transferencia interbancaria<span> Inmediata</span> a su cuenta bancaria!</p>
    </div>
  );
};

export default LeftBlock;
