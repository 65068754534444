import postImg1 from "../assets/images/prestamos-online-rapidos-y-seguros-en-doctor-sol.jpg";
import postImg2 from "../assets/images/prestamos-online-al-instante.jpg";
import postImg3 from "../assets/images/doctor-sol-es-confiable.jpg";

export const posts = [
  {
    title: "Préstamos en Línea Perú: Rápidos y Seguros",
    text: "Préstamos en Línea con Doctor Sol: Tu Opción Ideal en Perú ¿Te ha surgido una emergencia y necesitas un préstamo de manera inmediata? En Doctor Sol, estamos listos para asistirte..",
    img: postImg1,
    link: "https://doctorsol.pe/blogprestamos-en-linea-peru-rapidos-y-seguros/",
  },
  {
    title: "Préstamos Online Al Instante",
    text: "Ofrecemos préstamos online al instante incluso si no tienes historial Ofrecemos préstamos online al instante, seguros y confiables, accesibles incluso si tu historial crediticio tiene algunas manchas..",
    img: postImg2,
    link: "https://doctorsol.pe/blogprestamos-online-al-instante/",
  },
  {
    title: "Doctor Sol es Confiable",
    text: "Doctor Sol es Confiable: Tu Mejor Aliado para Préstamos en Perú Cuando buscas un préstamo online en Perú, la pregunta clave es: “¿Doctor Sol es confiable?” Hoy queremos hablarte de corazón..",
    img: postImg3,
    link: "https://doctorsol.pe/blogdoctor-sol-es-confiable/",
  },
];
