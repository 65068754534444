import React from 'react';

import "./styles.scss"

const InitLoader = () => {
  return (
    <div className={'init-loader'}>
      <div className={'init-loader-spinner'}></div>
    </div>
  );
};

export default InitLoader;
