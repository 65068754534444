import React from "react";

import "./styles.scss";

const GreenButton = ({
  disabled,
  isFullWidth,
  children,
  onClick,
}) => {
  return (
    <div
      className={`green-button ${disabled ? "disabled" : ""} ${isFullWidth ? "w-100" : ""}`}
      onClick={(e) => onClick(e)}
    >
      {children}
    </div>
  );
};

export default GreenButton;
