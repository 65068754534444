import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/mousewheel";
import { feedbacks } from "../../consts/feedbacks";

import "./styles.scss";

const Carousel = () => {
  return (
    <div className="feedback-block" id="carouselPoints">
      <Swiper
        modules={[Pagination, Mousewheel]}
        watchSlidesProgress={true}
        initialSlide={0}
        loop={true}
        centeredSlides={false}
        slidesPerView={1}
        grabCursor={true}
        slidesPerGroupSkip={1}
        slidesPerGroup={1}
        spaceBetween={15}
        pagination={{ el: ".pagination_block", clickable: true }}
        breakpoints={{
          768: {
            slidesPerView: 2,
          }
        }}
      >
        {
          feedbacks.map( ( feedback ) =>
            <SwiperSlide key={feedback.name} className="item">
              <div className="feedback-person">
                <img alt="userPic" src={feedback.userPic} />
                <div className="feedback-person__personal">
                  <div className="feedback-person__info">
                    <p>{feedback.name}</p>
                    {/* <p>
                      <span>
                        {feedback.city}
                      </span>
                    </p> */}
                  </div>
                  {/* <div className="feedback-person__info">
                    <img
                    alt="userPic" src={feedback.ratingPic} />
                    <p>
                      <span>
                        {feedback.date}
                      </span>
                    </p>                
                  </div>               */}
                </div>
              </div>
              <div className="feedback-content">
                <p>
                  {feedback.text}
                </p>
              </div>
            </SwiperSlide>
          )
        }
      </Swiper>
      <div className="pagination_block"></div>
    </div>
  );
};

export default Carousel;
