import { useCallback, useEffect, useMemo, useState } from "react";

const useScrollPosition = () => {
  // ToDo: refactoring
  const [smallTopOffset, setSmallTopOffset] = useState(false);
  const [middleTopOffset, setMiddleTopOffset] = useState(false);

  const isSmallTopOffset = useMemo(() => smallTopOffset, [smallTopOffset]);
  const isMiddleTopOffset = useMemo(() => middleTopOffset, [middleTopOffset]);

  const handleScroll = useCallback((e) => {
    const scrollTop = e.target.documentElement.scrollTop;

    setSmallTopOffset(scrollTop > 20);
    setMiddleTopOffset(scrollTop > 200);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [ handleScroll ]);

  return {
    isSmallTopOffset,
    isMiddleTopOffset,
  };
};

export default useScrollPosition;
