import React from "react";

import "./styles.scss";

const PaymentBoxItem = ({ className='', title, body, children, onClick, onMouseEnter, onMouseLeave, hasTooltip = false }) => {

  const handleInfoClick = ( e ) => {
    if ( hasTooltip ) {
      onClick(e, { title, body })
    }
  }

  return (
    <div
      className={`logo-payment ${className} ${hasTooltip ? 'pointer' : ''}`}
      onClick={handleInfoClick}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter} >    
      {children}
    </div>
  );
};

export default PaymentBoxItem;
