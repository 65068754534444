import React from "react";
import { Link } from "react-router-dom";

import { useAuthState } from '@/contexts/AuthContext';

import "./styles.scss";

const HeaderMenuBar = ({ show }) => {
  const { authZoneLink } = useAuthState();

  return (
    <>
      <div className={`menu-bar-bg ${show ? "active" : ""}`} />
      <div className={`menu-bar ${show ? "active" : ""}`}>
        <ul className="list-href">
          <li>
            <Link
              to="/sobre-nosotros"
              className="desktop-second-menu_point"
            >
              Sobre nosotros
            </Link>
          </li>
          <li>
            <Link
              to="/?section=calculator"
              className="desktop-second-menu_point"
            >
              Solicita tu Crédito
            </Link>
          </li>
          <li>
            <a
              href={authZoneLink}
              className="desktop-second-menu_point"
            >
              Realizar el pago
            </a>
          </li>        
          {/* <li>
            <Link
              to="/como-funciona"
              className="desktop-second-menu_point"
            >
              ¿Cómo funciona?
            </Link>
          </li> */}
          <li>
            <Link
              to="/preguntas-frecuentes"
              className="desktop-second-menu_point"
            >
              Preguntas frecuentes
            </Link>
          </li>
          <li>
            <Link
              to="/requests?page=contacts"
              className="desktop-second-menu_point"
            >
              Contáctenos
            </Link>
          </li>
          <li>
            <Link
              to="/requests?page=report"
              className="desktop-second-menu_point"
            >
              Informar un problema
            </Link>
          </li>
          <li>
            <a href="https://doctorsol.pe/blog">
              Blog
            </a>
          </li>
        </ul>
      </div>
    </>
  )
};

export default HeaderMenuBar;
