import React, { useState } from "react";

import PaymentBoxItem from "../../PaymentBoxItem";

import monnet from "@assets/images/monnet.svg";
import bbva from "@assets/images/bbva.svg";
import openPay from "@assets/images/open-pay.svg";
import orangeWarning from "@assets/images/orange-warning.svg";
import bannerYapi from "@assets/images/bannerYapi.png";

const MiddleBlock = ({ isMain, isActive, onPaymentBlockClick }) => {
  const [ hasTooltip, setTooltipDisplay ] = useState( false ) 

  return (
    <div
      className={`point-menu-text ${isActive ? "active" : ""}`}
      id="middle"
    >
      <p>Puedes pagar el crédito pendiente tanto en línea como en efectivo</p>
      <div className="additional-info">
        <div className="box-payment-flex">
          <PaymentBoxItem
            title="BBVA"
            hasTooltip={isMain}
            body={<><b>FAST FINANCE SOLUTIONS S.A.C. <br /> RUC 20610415769 <br /> BANCO BBVA PERU </b> <br /> Account number: <b>0011-0368-0100054010</b> <br /> SWIFT: <b>BCONPEPL</b> <br />CCI: <b>011368-000100054010-82</b></>}
            onClick={onPaymentBlockClick}
            onMouseEnter={() => setTooltipDisplay( true )}
            onMouseLeave={() => setTooltipDisplay( false )}
          >
            <img alt="bbva" src={bbva} />
          </PaymentBoxItem>
          <div className={`tooltip ${isMain ? 'active' : ''}`}>
            <div className={`tooltip__body ${hasTooltip && isMain ? 'active' : ''}`}>
              <b>FAST FINANCE SOLUTIONS S.A.C. <br /> RUC 20610415769 <br /> BANCO BBVA PERU </b> <br /> Account number: <b>0011-0368-0100054010</b> <br /> SWIFT: <b>BCONPEPL</b> <br />CCI: <b>011368-000100054010-82</b>
            </div>
          </div>          
          <PaymentBoxItem
            title="Transferencia bancaria"
            body={<>Para realizar su pago vía transferencia bancaria, debe hacerlo desde su internet banking a esta información: <b> VIAFINTECH SOLUTIONS, SRL <br /> Cuenta     corriente     corporativa <br /> (peso): 4060016976 <br /> RNC: 132305663 <br /> BANCO BDI S.A. </b> en el comentario debe colocar su numerode cédula y enviarnos el comprobante al whatsapp 1-809-833-3131.</>}
          >
            <img alt="monnet" src={monnet} />
          </PaymentBoxItem>
        </div>
        <div className="box-payment-flex">
          <PaymentBoxItem
            title="Transferencia bancaria"
            className="full-width"
            body={<>Para realizar su pago vía transferencia bancaria, debe hacerlo desde su internet banking a esta información: <b> VIAFINTECH SOLUTIONS, SRL <br /> Cuenta     corriente     corporativa <br /> (peso): 4060016976 <br /> RNC: 132305663 <br /> BANCO BDI S.A. </b> en el comentario debe colocar su numerode cédula y enviarnos el comprobante al whatsapp 1-809-833-3131.</>}
            onClick={onPaymentBlockClick}
          >
            <img alt="openPay" src={openPay} />
          </PaymentBoxItem>
        </div>
        <div className="box-payment-flex banner">
          <img alt="Yapi" src={bannerYapi} />
        </div>
        <div className="main-box orange-box">
          <img alt="orangeWarning" src={orangeWarning} />
          <p>Ya estamos trabajando en agregar nuevos métodos de pago</p>
        </div>
      </div>
    </div>
  );
};

export default MiddleBlock;
