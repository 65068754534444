import React from "react";

const NavigationSection = () => {
  return (
    <div className="container-page">
      <ul className="desktop-second-menu container-page only-desk" id="desktop-second-menu">
      </ul>
    </div>
  )
};

export default NavigationSection;
