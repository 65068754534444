import React, { useEffect } from "react";

import "./styles.scss";
import logo from "@/assets/images/prestamo-personal-online-instante-doctorsol.svg";

const MessageOnlyMobile = () => {
  useEffect(() => {
    document.querySelector('body').style.overflow = 'hidden';
  }, []);

  return (
    <div className="message-only-mobile">
        <div className="message-only-mobile__image">
          <img alt="Logotipo de DoctorSol" src={logo} />
        </div>
        <p>Only mobile version</p>
    </div>
  );
};

export default MessageOnlyMobile;
