import React from "react";

import "./styles.scss";

const InformationModal = ({ show, title = "", body = "", onClose }) => {
  return (
    <>
      <div
        className={`modal-bg click-information-toggle ${show ? "show" : ""}`}
        onClick={() => onClose()}
      />
      <div className={`toggle-information ${show ? "show" : ""}`}>
        <div className="toggle-information-header">
          <h4 className="toggle-information-title">
            {title}
          </h4>
          <div
            className="click-information-toggle toggle-doing"
            onClick={() => onClose()}
          >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.40156 7.99961L15.1016 2.29961C15.5016 1.89961 15.5016 1.29961 15.1016 0.899609C14.7016 0.499609 14.1016 0.499609 13.7016 0.899609L8.00156 6.59961L2.30156 0.899609C1.90156 0.499609 1.30156 0.499609 0.901563 0.899609C0.501563 1.29961 0.501563 1.89961 0.901563 2.29961L6.60156 7.99961L0.901563 13.6996C0.501563 14.0996 0.501563 14.6996 0.901563 15.0996C1.10156 15.2996 1.40156 15.3996 1.60156 15.3996C1.80156 15.3996 2.10156 15.2996 2.30156 15.0996L8.00156 9.39961L13.7016 15.0996C13.9016 15.2996 14.2016 15.3996 14.4016 15.3996C14.6016 15.3996 14.9016 15.2996 15.1016 15.0996C15.5016 14.6996 15.5016 14.0996 15.1016 13.6996L9.40156 7.99961Z"
                fill="#A7A7A7" />
            </svg>
          </div>
        </div>
        <div className="toggle-information-body">
          {body}
        </div>
      </div>
    </>
  );
};

export default InformationModal;
