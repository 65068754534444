import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/mousewheel";
import "swiper/css/autoplay";

import "./styles.scss";

import Picture1 from "@/assets/images/interes-en-tu-primer-prestamo.png";
import Picture2 from "@/assets/images/mobile-app.png";
import Picture3 from "@/assets/images/image_web_alerta.png";

import { socialMediaLinks } from "@consts/socialMediaLinks.js";

const Carousel = () => {
  return (
    <div className="main-carousel-block">
      <Swiper
        spaceBetween={30}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Mousewheel]}
        grabCursor={true}
        loop={true}
      >
        <SwiperSlide>
          <span className="main-carousel-block__slide">slide 1</span>
          <a target="_blank" href={socialMediaLinks.mobileApp} rel="noreferrer">
            <img src={Picture1} alt="Persona sonriente con dinero en efectivo y un celular" />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <span className="main-carousel-block__slide">slide 2</span>
          <a target="_blank" href={socialMediaLinks.mobileApp} rel="noreferrer">
            <img src={Picture2} alt="Mobile App" />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <span className="main-carousel-block__slide">slide 3</span>
          <a target="_blank" href={socialMediaLinks.mobileApp} rel="noreferrer">
            <img src={Picture3} alt="Mobile App" />
          </a>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Carousel;
