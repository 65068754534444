import React from "react";

const MainInformationPage = React.lazy(() => import("@Pages/MainInformation"));
const RequestsPage = React.lazy(() => import("@Pages/Requests"));
const ComoUsarloPage = React.lazy(() => import("../pages/ComoUsarlo"));
const DocumentsPage = React.lazy(() => import("../pages/Documents"));

const projectCode = process.env.REACT_APP_PROJECT_CODE || "test";

const routes = [
  {
    path: "/main-information",
    element: <MainInformationPage />,
  },
  {
    path: "/como-funciona",
    element: <ComoUsarloPage />,
  },
  {
    path: "/requests",
    element: <RequestsPage />,
  },
  {
    path: "/documents",
    element: <DocumentsPage />,
  }
];

if (projectCode === 'doctorsol.pe' || projectCode === 'test-web.doctorsol.pe') {
  const PreguntasFrecuentesPage = React.lazy(() => import("../sites/doctorsol.pe/pages/PreguntasFrecuentes"));
  const SobreNosotrosPage = React.lazy(() => import("../sites/doctorsol.pe/pages/SobreNosotros"));

  routes.push(
    {
      path: "/preguntas-frecuentes",
      element: <PreguntasFrecuentesPage />
    },
    {
      path: "/sobre-nosotros",
      element: <SobreNosotrosPage />
    }
  );
}

export default routes;
