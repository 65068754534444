import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel } from "swiper";

import "swiper/css";
import "swiper/css/mousewheel";

import { posts } from "@consts/postsBlog";

import "./styles.scss";

const PostsBlog = () => {
  return (
    <div className="posts-blog">
      <Swiper
        modules={[Mousewheel]}
        initialSlide={0}
        loop={true}
        centeredSlides={false}
        slidesPerView={1}
        grabCursor={true}
        slidesPerGroupSkip={1}
        slidesPerGroup={1}
        spaceBetween={8}
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}>
        {posts.map((post) => (
          <SwiperSlide key={post.title} className="posts-blog__post">
            <div className="posts-blog__post-img">
              <img alt={post.title} src={post.img} />
            </div>
            <div className="posts-blog__post-content">
              <a
                href={post.link}
                rel="noreferrer"
                className="posts-blog__post-link">
                {post.title}
              </a>
              <p>{post.text}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default PostsBlog;
