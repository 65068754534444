import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";

import "./styles.scss";
import InformationModal from "@Common/InformationModal";
import LeftBlock from "./DescriptionChooseItems/LeftBlock";
import MiddleBlock from "./DescriptionChooseItems/MiddleBlock";
import RightBlock from "./DescriptionChooseItems/RightBlock";

import howToUseLeft from "@assets/images/how-to-use-left.png";
import howToUseMiddle from "@assets/images/how-to-use-middle.png";
import howToUseRight from "@assets/images/how-to-use-middle.png";

const ComoUsarloBlock = ({isPage, isMain = false}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [activeBlockPosition, setActiveBlockPosition] = useState("left");

  const activeImgSrc = useMemo(() => {
    switch (activeBlockPosition) {
      case "left":
        return howToUseLeft;
      case "middle":
        return howToUseMiddle;
      case "right":
        return howToUseRight;
      default:
        return howToUseLeft;
    }
  }, [activeBlockPosition]);

  const headerText = useMemo(() => (
    <h2 className="title-block">¿Сómo funciona?</h2>
  ), []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const howDoesItWorkBlock = useMemo(() => {
    const changeActiveBlockPosition = (position) => {
      setActiveBlockPosition(position);
    };
    const handlePaymentModalOpen = (e, {title, body}) => {
      if ( title === 'BBVA' ) {
        setModalBody(body);
        setModalTitle(title);
        setShowModal(true);
      }
    };

    return (
      <div className={`how-does-it-work ${isPage ? "full-width" : ""}`}>
      {!isPage && headerText}
      <div className="switch-menu">
        <div
          className="point-menu-active"
          data-active={activeBlockPosition}
        />
        <div
          className={`point-menu ${activeBlockPosition === "left" ? "active" : ""}`}
          onClick={() => changeActiveBlockPosition("left")}
        >
          Solicitar
        </div>
        <div
          className={`point-menu ${activeBlockPosition === "middle" ? "active" : ""}`}
          onClick={() => changeActiveBlockPosition("middle")}
        >
          Pagar
        </div>
        <div
          className={`point-menu ${activeBlockPosition === "right" ? "active" : ""}`}
          onClick={() => changeActiveBlockPosition("right")}
        >
          Extender
        </div>
      </div>

      <div className="description-choose">
        <LeftBlock isActive={activeBlockPosition === "left"}/>
        <MiddleBlock
          isActive={activeBlockPosition === "middle"}
          isMain={isMain}
          onPaymentBlockClick={handlePaymentModalOpen}
        />
        <RightBlock
          isActive={activeBlockPosition === "right"}
          onPaymentBlockClick={handlePaymentModalOpen}
        />
      </div>
    </div>
    )
  }, [headerText, activeBlockPosition, isPage]);

  const descriptionChooseBlock = useMemo(() => (
    <div
      className={`description-choose-img point-menu-img ${!isPage ? "hidden" : ""}`}
      data-img={activeBlockPosition}
    >
      {/*<picture>*/}
      {/*  <source srcSet={activeImgSrcWebp} type="image/webp"/>*/}
      {/*  <source srcSet={activeImgSrc} type="image/png"/>*/}
      {/*  */}
      {/*</picture>*/}
      {!isPage && <img alt="" src={activeImgSrc}/>}
    </div>
  ), [activeBlockPosition, isPage, activeImgSrc]);

  const accederBlock = useMemo(() => (
    <div className="acceder">
      <div>
        <p>Acceda con su cuenta personal</p>
        <Link to="/?section=calculator" className="black-button w-100">
          Registrese ahora
        </Link>
      </div>
    </div>
  ), []);

  return (
    <>
      <InformationModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        onClose={() => handleCloseModal()}
      />

      <div className={`${!isPage ? "block-3-main-container" : ""}`}>
        {isPage && (
          <>
            {headerText}
            <div className="como-usarlo-flex">
              <div className="forms-block_form">
                {howDoesItWorkBlock}
                {descriptionChooseBlock}
              </div>
              {accederBlock}
            </div>
          </>
        )}
        {!isPage && howDoesItWorkBlock}
        {!isPage && descriptionChooseBlock}
      </div>
    </>
  );
};

export default ComoUsarloBlock;
