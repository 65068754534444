import React from "react";

import "./styles.scss";

import checkPicture from "@assets/images/check_picture.svg";
import blMan from "@assets/images/prestamo-personal-online-instante-proceso.png";
import blManWebp from "@assets/images/prestamo-personal-online-instante-proceso.webp";

const PorSection = () => {
  return (
    <div className="container-width-100 ">
      <div className="block-4" id="block-4">
        <div className="container-page">
          <div className="block-4-main-container">
            <h2 className="title-block color-white">¿Por Qué Elegir Doctor Sol para tus préstamos urgentes?</h2>
            <div className="block-4-description">
              <div className="columns-flex">
                <img alt="check" className="img-point" src={checkPicture} />
                <h3>Aprobamos 100% online, más  rápido y fácil que los bancos</h3>
              </div>
              <div className="columns-flex">
                <img alt="check" className="img-point" src={checkPicture} />
                <h3>Ofrecemos préstamos seguros y transparentes</h3>
              </div>
              <div className="columns-flex">
                <img alt="check" className="img-point" src={checkPicture} />
                <h3>Flexibilidad de plazos de pago, de 7 a 30 días</h3>
              </div>
              <div className="columns-flex">
                <img alt="check" className="img-point" src={checkPicture} />
                <h3>Posibilidad de extender el pago si lo necesitas</h3>
              </div>
            </div>
          </div>
          <picture>
            <source srcSet={blManWebp} type="image/webp"/>
            <source srcSet={blMan} type="image/png" />
            <img className="abs-posotion-img" alt="Solicitud de un préstamo personaL" src={blMan} />
          </picture>
        </div>
      </div>
    </div>
  );
};

export default PorSection;
