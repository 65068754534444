import React from "react";

import orangeWarning from "@assets/images/orange-warning.svg";

const RightBlock = ({ isActive, onPaymentBlockClick }) => {
  return (
    <div
      className={`point-menu-text ${isActive ? "active" : ""}`}
      id="right"
    >
      <p>Hay 3 opciones de extensión disponibles: 7, 14, 30 días. Puedes pagar el crédito
        pendiente tanto en línea como en efectivo.</p>
      <div className="additional-info">
        <div className="additional-info">
          <div className="additional-info-body right-block">       
            <div className="main-box orange-box">
              <img alt="orangeWarning" src={orangeWarning} />
              <p>Ya estamos trabajando en agregar nuevos métodos de pago</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightBlock;
