import React from "react";
import scrollToTop from "@utils/scrollToTop";

import "./styles.scss";

const RequestSection = () => {
  return (
    <section className="container-width-100">
      <div className="block-6" id="block-6">
        <h2>Solicita tu primer préstamo con nosotros en minutos</h2>
        <p>Conoce desde el inicio cuánto debes pagar</p>
        <button
          className="block-6__button"
          onClick={() => scrollToTop()}
        >
          ¡SOLICÍTALO AQUÍ!
        </button>
      </div>
    </section>
  );
};

export default RequestSection;
