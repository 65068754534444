import React from "react";
import { socialMediaLinks } from "@consts/socialMediaLinks.js";

import "./styles.scss";
import logoGoogle from "@/assets/images/button-google-play.svg";

const LoadAppButtonOnlyMobile = ({ className }) => {
  return (
    <div className={`load-app-button-only-mobile ${className || ""}`}>
      <a
        target="_blank"
        href={socialMediaLinks.mobileApp}
        rel="noreferrer"
        className="load-app-button-only-mobile__link"
      >
        <p className="load-app-button-only-mobile__text">Descarga la App</p>
        <img
          alt="logo google play"
          className="load-app-button-only-mobile__img"
          src={logoGoogle}
        />
      </a>
    </div>
  );
};

export default LoadAppButtonOnlyMobile;
