import React from "react";

import "./styles.scss";
import ComoUsarloBlock from "@Components/ComoUsarloBlock";

const ComoUsarloSection = () => {

  return (
    <div className="block-3">
      <div className="container-page">
        <ComoUsarloBlock isMain={true} />
      </div>
    </div>
  );
};

export default ComoUsarloSection;
