import userPic from "../assets/images/feedback-facebook.svg"
import ratingPic from "../assets/images/rating.png"

export const feedbacks = [
  {
    name: 'Óscar Frias',
    city: 'Santo Domingo',
    text: 'Muy efectivos con los préstamos lo recomiendo',
    userPic,
    ratingPic,
    date: 'Ayer'
  },
  {
    name: 'Abel Carol Muñoz Sanchez',
    city: 'San Francisco de Macoris',
    text: 'Buenos días los recomiendo mucho es muy buena y son muy serios con la referencia y todo muchas gracias',
    userPic,
    ratingPic,
    date: '14 de abril de 2021'
  },
  {
    name: 'Adán Alberto Vasquez',
    city: 'Santiago de los Caballeros',
    text: 'Son   confiables, me prestaron a 30 días y me cobraron algo justo en intereses, no   son estafa, los demás sí ( los que pasaron en séptimo dia)',
    userPic,
    ratingPic,
    date: '14 de abril de 2021'
  },
  {
    name: 'Paco Pablo Perez Idalgo',
    city: 'Higuey',
    text: 'Es  buena a uno lo saca de apuros',
    userPic,
    ratingPic,
    date: '14 de abril de 2021'
  },
  {
    name: 'Vital Carlos de la Rosa Batista',
    city: 'San Pedro de Macoris',
    text: 'Hasta   el momento no puedo decir nada malo. Son amplios!',
    userPic,
    ratingPic,
    date: '14 de abril de 2021'
  },
  {
    name: 'Zoé Elizabet Mateo Gil',
    city: 'La Romana',
    text: 'Es  buena',
    userPic,
    ratingPic,
    date: '14 de abril de 2021'
  },
  {
    name: 'Susano Marcia Tapia Rodriguez',
    city: 'Santo Domingo',
    text: 'no   es estafa ni mentira, yo accedi a un crédito con ellos y al dia sgte lo   desembolsaron, importante atender la llamada al momento que corroboren   información y que no cometas inconsistencias de información al preguntarte ✌️ ',
    userPic,
    ratingPic,
    date: '14 de abril de 2021'
  },
  {
    name: 'Santos Jose Velazquez',
    city: 'Haina',
    text: 'Dr Sol excelente.... muchas gracias',
    userPic,
    ratingPic,
    date: '14 de abril de 2021'
  },
  {
    name: 'Ruperto Antonio Pimentel Castro',
    city: 'Santo Domingo',
    text: 'Si es muy buena',
    userPic,
    ratingPic,
    date: '14 de abril de 2021'
  },
  {
    name: 'Wilma Nadiuska Vasquez de la Cruz',
    city: 'San Pedro de Macoris',
    text: '¡Gracias por la ayuda! Rescatado en el momento adecuado. Ahora, cuando el sueldo se retrasa, sé adónde ir.',
    userPic,
    ratingPic,
    date: '14 de abril de 2021'
  },
  {
    name: 'Rufo Antonio Rivera',
    city: 'La Romana',
    text: 'Una pagina chevere, transfiren de una vez Gracias',
    userPic,
    ratingPic,
    date: '14 de abril de 2021'
  },
  {
    name: 'Roberto Bautista Torres Espinal',
    city: 'Higuey',
    text: 'Gracias por la ayuda en mi nececidad real. Tienen un sistema de préstamos muy conveniente. Y lo más importante, alta eficiencia en el trabajo del sistema.',
    userPic,
    ratingPic,
    date: '14 de abril de 2021'
  },
]