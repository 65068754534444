import React from "react";

import "./styles.scss";
import PostsBlog from "@Components/PostsBlog";

const BlogSection = () => {
  return (
    <section className="blog-section container-width-100">
      <div className="container-page">
        <div className="blog-section__main-container">
          <h2 className="post-title">
            <span className="mobile-text">Blog: </span>
            Consejos financieros y tips para manejar tu préstamo personal
          </h2>
          <PostsBlog />
          <div className="blog-section__button">
            <a href="https://doctorsol.pe/blog" rel="noreferrer">
              VISITAR BLOG
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogSection;
