import React from "react";
import Helmet from "react-helmet";

const jsonLD = JSON.stringify({
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "name": "Doctor Sol",
  "url": "https://doctorsol.pe/",
  "logo": "https://doctorsol.pe/static/media/drsol_logo%201.3e773dc77ae5e369816fd40fef8c01e8.svg",
  "description": "Préstamos personales rápidos y fáciles en línea. Obtén hasta S/400 soles en solo 30 minutos sin papeleo ni burocracia.",
  "telephone": "+51 1 623 1170",
  "email": "contacto@doctorsol.pe",
  "address": {
    "@type": "PostalAddress",
    "addressLocality": "Lima",
    "addressRegion": "Lima",
    "addressCountry": "PE"
  },
  "sameAs": [
    "https://www.facebook.com/profile.php?id=100092506849635",
    "https://www.tiktok.com/@doctor_sol",
    "https://www.instagram.com/doctorsol.pe/"
  ],
  "priceRange": "S/100 - S/400",
  "paymentAccepted": ["Cash", "Credit Card", "Debit Card"]
});

const HelmetHeadContent = () => {
  return (
    <Helmet>
      <link rel="canonical" href="https://doctorsol.pe/" />
      <link rel="alternate" hreflang="es-pe" href="https://doctorsol.pe" />

      {/* Google tag (gtag.js) */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11210630201"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-11210630201');
        `}
      </script>
      {/* End Google tag (gtag.js) */}

      <script type="application/ld+json">{jsonLD}</script>
    </Helmet>
  );
};

export default HelmetHeadContent;
