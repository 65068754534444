import React from "react";

import "./styles.scss";
import ballTriangle from "../../assets/images/ball-triangle.svg";

const Loader = () => {
  return (
    <div className="preloader-box">
      <div className="preloader-flex">
        <img src={ballTriangle} alt="Triangle" />
      </div>
    </div>
  );
};

export default Loader;
