import React from "react";
import { Link } from "react-router-dom";

import "./styles.scss";
import Carousel from "@Common/Carousel";

const FeedbackSection = () => {
  return (
    <section className="container-width-100">
      <div className="block-5" id="block-5">
        <div className="container-page">
          <div className="block-5-main-container">
            <h2 className="title-block color-white">Testimonios <br className="mobile" /> de nuestros clientes</h2>
            <Carousel />
            <div className="carousel-button">
              <Link to="/requests?page=feedback">Deja tu comentario</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeedbackSection;
